import * as React from 'react';

const NotFoundPage = () => {
  return (
    <main>
      <p>404 not found</p>
    </main>
  );
};

export default NotFoundPage;
